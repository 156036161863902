import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AddressAutofill, useConfirmAddress } from '@mapbox/search-js-react';
import { X } from "@phosphor-icons/react";
import { useGetNextState } from "../../hooks/useGetNextState";
import { analytics } from "../../analytics";
import Button from "../ButtonComponent";
import TextInput from "../TextInput";
import { useLocationWrapper } from "../../hooks/useLocationWrapper";
import { datadogRumService } from "../../services/datadogRum";
import ShieldIcon from "./ConnectIntro/components/shield.svg";
import PrivateIcon from "./ConnectIntro/components/private.svg";
import { AddressAutofillFeatureSuggestion } from "@mapbox/search-js-core";

interface ILocation {
  streetOne: string;
  streetTwo?: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

interface IState {
  customerInfo: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    location?: Partial<ILocation>;
  };
}

interface ILocationOverlayProps {
  open?: boolean;
  onClose: () => void;
}

const mapboxKey = import.meta.env.VITE_MAPBOX_EMBEDDED_KEY;

function ConnectLocationOverlay({
  open = false,
  onClose,
}: ILocationOverlayProps) {
  const dialogRef = React.useRef<HTMLDialogElement>(null);

  const handleClose = () => {
    dialogRef.current?.close();
    onClose();
  }

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      dialogRef.current?.showModal();
    } else {
      document.body.style.overflow = "unset";
      dialogRef.current?.close();
    }
  }, [open]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (open && event.key === "Escape") {
        handleClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  return (
    <dialog ref={dialogRef}>
      {/* overlay content */}
      <div className="flex flex-col items-center bg-white relative">
        {/* overlay header */}
        <div className="flex items-center w-full p-2 border-b">
          <button
            type="button"
            className="mr-auto"
            onClick={handleClose}
          >
            <X className="w-8 h-8 text-texturePennBlue" />
          </button>

          <div className="mr-auto">Address</div>
        </div>

        {/* overlay custom content */}
        <div className="flex flex-col items-center p-2 max-w-[900px]">
          <div className="flex flex-col items-center justify-between gap-4 mb-12 p-2">
            <div className="flex flex-col gap-4">
              <p>
                Your address will allow us to provide you with an optimized experience by giving us the ability to identify your local utility, apply weather forecasts to your energy data, provide you with local energy insights, and much more.
              </p>
              <p>
                Your address will be:
              </p>
            </div>
            <div className="flex flex-col items-start sm:items-center mb-8 sm:mb-0">
              <span className="text-base font-bold text-texturePennBlue flex flex-row items-center gap-1.5 mb-1.5">
                <img src={ShieldIcon} alt="shield" className="w-4 h-4" />
                Secure
              </span>
              <p className="text-base text-texturePennBlue font-normal leading-5 sm:text-center sm:w-56">
                Your data is stored at industry-leading standards.
              </p>
            </div>

            <div className="flex flex-col items-start sm:items-center">
              <span className="text-base font-bold text-texturePennBlue flex flex-row items-center gap-1.5 mb-1.5">
                <img src={PrivateIcon} alt="shield" className="w-4 h-4" />
                Private
              </span>
              <p className="text-base text-texturePennBlue font-normal leading-5 sm:text-center sm:w-56">
                Your data will be encrypted and not shared or sold.
              </p>
            </div>
          </div>

          <div className="w-full sm:w-[30rem] flex flex-col items-center gap-8 tp:gap-4">
            <Button type="button" onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </dialog>
  );
}

export function ConnectLocation() {
  const { linkToken = "" } = useParams();
  const { state } = useLocationWrapper<IState>();
  const { getNextState, loading } = useGetNextState(linkToken);

  const [firstName, setFirstName] = useState(state?.customerInfo.firstName || "");
  const [lastName, setLastName] = useState(state?.customerInfo.lastName || "");
  const [email, setEmail] = useState(state?.customerInfo.email || "");
  const [phone, setPhone] = useState(state?.customerInfo.phone || "");

  const [showLocationOverlay, setShowLocationOverlay] = useState(false);

  const [streetOne, setStreetOne] = useState(state?.customerInfo.location?.streetOne || "");
  const [streetTwo, setStreetTwo] = useState(state?.customerInfo.location?.streetTwo || "");
  const [city, setCity] = useState(state?.customerInfo.location?.city || "");
  const [addressState, setAddressState] = useState(state?.customerInfo.location?.state || "");
  const [postalCode, setPostalCode] = useState(state?.customerInfo.location?.postalCode || "");
  const [country, setCountry] = useState(state?.customerInfo.location?.country || "United States");

  const { formRef, showConfirm } = useConfirmAddress({
    accessToken: mapboxKey,
  });

  const handleMapboxLocationChange = (newLocation: AddressAutofillFeatureSuggestion): ILocation => {
    const location = {
      streetOne: newLocation.properties.address_line1 || '',
      streetTwo: newLocation.properties.address_line2 || undefined,
      city: newLocation.properties.address_level2 || '',
      state: newLocation.properties.address_level1 || '',
      country: newLocation.properties.country || '',
      postalCode: newLocation.properties.postcode || '',
    };

    setStreetOne(location.streetOne);
    setStreetTwo(location.streetTwo || '');
    setCity(location.city);
    setAddressState(location.state);
    setCountry(location.country);
    setPostalCode(location.postalCode);

    return location;
  };

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();

    const result = await showConfirm();

    if (result.type === 'cancel') {
      return;
    }

    let location: ILocation = {
      streetOne,
      streetTwo: streetTwo || undefined,
      city,
      state: addressState,
      country,
      postalCode,
    };

    if (result.type === 'change') {
      location = handleMapboxLocationChange(result.feature);
      console.log('entered location updated', result.feature, location);
    }

    console.log(location);

    getNextState("location", {
      type: "continue",
      data: {
        customerInfo: {
          firstName,
          lastName,
          email,
          phone: phone || undefined,
          location,
        },
      },
    });
  };

  const handleToggleLocationOverlay = () => {
    setShowLocationOverlay(prevLocationOverlay => !prevLocationOverlay);
  }

  // Effect handling analytics page view
  useEffect(() => {
    if (analytics && analytics.page) {
      analytics.page("connect location");
    }
  }, []);

  // update datadog rum user attributes
  useEffect(() => {
    datadogRumService.updateUser({
      firstName,
      lastName,
      email,
      phone,
    });
  }, [firstName, lastName, email, phone]);

  return (
    <form className="flex flex-col items-center w-full gap-12" onSubmit={handleSubmit} ref={formRef}>
      <div className="flex flex-col items-center justify-between gap-8 tp:gap-4 w-full">
        <div className="text-texturePennBlue font-bold text-xl d:text-4xl tp:text-2xl text-left -mb-4 w-full sm:w-[30rem]">
          Tell us a little about yourself
        </div>

        <div className="w-full sm:w-[30rem]">
          <TextInput autoFocus={true} required={true} minLength={1} maxLength={100} label="First name" autoComplete="given-name" value={firstName} onChange={setFirstName} />
        </div>
        <div className="w-full sm:w-[30rem]">
          <TextInput required={true} minLength={1} maxLength={100} label="Last name" autoComplete="family-name" value={lastName} onChange={setLastName} />
        </div>
        <div className="w-full sm:w-[30rem]">
          {/* modified pattern from zod to match backend validation */}
          {/* https://github.com/colinhacks/zod/blob/826d6a22a008ed38b64a6deb405a59b6ba74b6af/src/types.ts#L599-L600 */}
          <TextInput required={true} type="email" autoComplete="email" label="Email" value={email} pattern="(?!\.)(?!.*\.\.)([a-zA-Z0-9_'+\-\.]*)[a-zA-Z0-9_+\-]@([a-zA-Z0-9][a-zA-Z0-9\-]*\.)+[a-zA-Z]{2,}" onChange={setEmail} />
        </div>
        <div className="w-full sm:w-[30rem]">
          <TextInput type="tel" minLength={5} maxLength={20} autoComplete="tel" label="Phone number" value={phone} onChange={setPhone} />
        </div>
      </div>

      <div className="flex flex-col items-center justify-between gap-8 tp:gap-4 w-full">
        <div className="text-texturePennBlue font-bold text-xl d:text-4xl tp:text-2xl text-left -mb-4 w-full sm:w-[30rem]">
          What is your address?
        </div>

        <div className="w-full sm:w-[30rem]">
          <AddressAutofill
            accessToken={mapboxKey}
            onRetrieve={({ features }) => {
              const country = features[0]?.properties?.country || '';
              setCountry(country);
            }}
            // https://docs.mapbox.com/mapbox-search-js/api/core/autofill/#addressautofilloptions
            options={{
              streets: false,
            }}
          >
            <div className="flex flex-col w-full sm:w-[30rem] gap-8">
              <TextInput required={true} label="Address" autoComplete="address-line1" value={streetOne} onChange={setStreetOne} />
              <TextInput label="Apt/Suite" autoComplete="address-line2" value={streetTwo} onChange={setStreetTwo} />
              <TextInput required={true} label="City" autoComplete="address-level2" value={city} onChange={setCity} />
              <TextInput required={true} label="State" autoComplete="address-level1" value={addressState} onChange={setAddressState} />
              <TextInput required={true} label="Zip code" autoComplete="postal-code" value={postalCode} onChange={setPostalCode} />
              <TextInput type="hidden" hidden={true} label="Country" autoComplete="country-name" value={country} onChange={setCountry} />
            </div>
          </AddressAutofill>
        </div>

        <ConnectLocationOverlay open={showLocationOverlay} onClose={handleToggleLocationOverlay} />

        <Button type="button" link={true} secondary={true} onClick={handleToggleLocationOverlay}>
          Why do you need my address?
        </Button>
      </div>

      <div className="w-full sm:w-[30rem] flex flex-col items-center gap-8 tp:gap-4">
        <Button type="submit" disabled={loading} loading={loading}>
          Continue
        </Button>
      </div>
    </form>
  );
}
