import { INextState } from "./hooks/useGetNextState";
import { SESSION_STORAGE_KEYS, sessionStorageService } from "./services/sessionStorage";

interface IStateUrlOptions {
  manufacturerSlug?: string;
}

interface IMessage<T = unknown> {
  type: string;
  data?: T;
}

export interface IErrorResponse {
  code: string;
  message: string;
}

// Should be kept in sync with the same enum in apps/connect/api/src/constants/connectState.ts
// @todo: Move to a shared package/location
export const enum CONNECT_STATE {
  START = "start",
  INTRO = "intro",

  MANUFACTURER_SELECTION = "manufacturers",
  MANUFACTURER_OAUTH = "manufacturers/oauth",
  MANUFACTURER_LOGIN = "manufacturers/login",
  MANUFACTURER_LOGIN_MFA = "manufacturers/login/mfa",

  LOCATION = "location",

  OAUTH_CODE = "oauth:code",
  SUCCESS = "success",
  ERROR = "error",
}

function getUrlForState(linkToken: string, state: string, options: IStateUrlOptions = {}): string | null {
  switch (state) {
    case CONNECT_STATE.START:
      return `/${linkToken}`;
    case CONNECT_STATE.INTRO:
      return `/${linkToken}/intro`;
    case CONNECT_STATE.MANUFACTURER_SELECTION:
      return `/${linkToken}/manufacturers`;
    case CONNECT_STATE.MANUFACTURER_OAUTH:
      return `/${linkToken}/manufacturers/${options.manufacturerSlug}`;
    case CONNECT_STATE.MANUFACTURER_LOGIN:
      return `/${linkToken}/manufacturers/${options.manufacturerSlug}/login`;
    case CONNECT_STATE.MANUFACTURER_LOGIN_MFA:
      return `/${linkToken}/manufacturers/${options.manufacturerSlug}/login/mfa`;
    case CONNECT_STATE.LOCATION:
      return `/${linkToken}/location`;
    case CONNECT_STATE.SUCCESS:
      return `/${linkToken}/success`;
    case CONNECT_STATE.ERROR:
      return `/${linkToken}/error`;
    default:
      return null;
  }
}

export function getUrlForNextState(linkToken: string, nextState: INextState): string | null {
  return getUrlForState(linkToken, nextState.next, {
    manufacturerSlug: nextState.data?.slug || "",
  });
}

export function sendHostMessage<T>(message: IMessage<T>): void {
  if (!window.opener || window.opener === window) {
    return;
  }

  const hostOrigin = sessionStorageService.get(SESSION_STORAGE_KEYS.hostOrigin);

  if (!hostOrigin) {
    console.warn("No host origin found in session storage");
    return;
  }

  window.opener.postMessage(message, hostOrigin);
}

export function onBeforeUnloadHandler(): void {
  sendHostMessage({
    type: "close",
  });
}

export function classNames(...args: (string | boolean | undefined)[]) {
  return args.filter(Boolean).join(" ");
}
